<template>
    <div class="fabricante">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalFabricante" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloFabricante }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmFabricante" v-model="validoFabricante" lazy-validation>
                                <v-text-field v-model="editFabricante.nombre" :rules="reglaNombreFabricante"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarFabricante">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoFabricante" color="success" class="mr-4"
                                        @click="guardarFabricante">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarFabricante" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrareliminarFabricante">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimFabricante">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersFabricante" :body="fabricantes" tituloTabla="FABRICANTE" :accionAgregar="true"
                :exportar="true" @recargar="listarFabricante" @agregar="agregarFabricante"
                @actualizar="editarFabricante" @eliminar="eliminarFabricante" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "fabricante",
        components: {
            tabla
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables fabricante 
            UidFabricante: null,
            buscarFabricante: '',
            validoFabricante: true,
            modalFabricante: false,
            modalEliminarFabricante: false,
            headersFabricante: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Fabricante',
                    align: 'start',
                    value: 'nombre',
                },

                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            fabricantes: [],
            reglaNombreFabricante: [
                v => !!v || 'El nombre del fabricante es obligatorio.'
            ],
            editedFabricanteIndex: -1,
            editFabricante: {
                nombre: ''
            },
            defaultFabricante: {
                nombre: ''
            },
            // fin variables fabricante
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal fabricante 
            tituloFabricante() {
                return this.editedFabricanteIndex === -1 ? 'Nuevo Fabricante' : 'Editar Fabricante'
            },
        },

        watch: {
            // modal fabricante
            modalFabricante(val) {
                val || this.cancelarFabricante()
            },
            modalEliminarFabricante(val) {
                val || this.cerrareliminarFabricante()
            },
            // fin modal fabricante
        },

        created() {
            // listar fabricantes
            this.listarFabricante()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarFabricante() {
                try {
                    await fetch(`${this.url_api}/fabricante`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.fabricantes = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            agregarFabricante() {
                this.modalFabricante = true;
            },
            editarFabricante(fabricante) {
                this.editedFabricanteIndex = this.fabricantes.indexOf(fabricante)
                this.editFabricante = Object.assign({}, fabricante)
                this.modalFabricante = true
                this.UidFabricante = fabricante.Uid;
            },

            eliminarFabricante(fabricante) {
                this.editedFabricanteIndex = this.fabricantes.indexOf(fabricante)
                this.editFabricante = Object.assign({}, fabricante)
                this.modalEliminarFabricante = true
                this.UidFabricante = fabricante.Uid;
            },

            async confirmarElimFabricante() {
                try {
                    await fetch(`${this.url_api}/fabricante/${this.UidFabricante}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.fabricantes.splice(this.editedFabricanteIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrareliminarFabricante()
            },

            cancelarFabricante() {
                this.$refs.frmFabricante.reset()
                this.$refs.frmFabricante.resetValidation()
                this.modalFabricante = false
                this.$nextTick(() => {
                    this.editFabricante = Object.assign({}, this.defaultFabricante)
                    this.editedFabricanteIndex = -1
                })
            },

            cerrareliminarFabricante() {
                this.modalEliminarFabricante = false
                this.$nextTick(() => {
                    this.editFabricante = Object.assign({}, this.defaultFabricante)
                    this.editedFabricanteIndex = -1
                })
            },

            async guardarFabricante() {
                if (this.$refs.frmFabricante.validate()) {
                    if (this.editedFabricanteIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/fabricante/${this.UidFabricante}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editFabricante)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato acrualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.fabricantes[this.editedFabricanteIndex], this
                                            .editFabricante);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidFabricante = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/fabricante`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editFabricante)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarFabricante();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al guardar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarFabricante()
                }
            },
            // fin CRUD fabricantes
        },
    }
</script>